import { createTheme } from '@mui/material';

declare module '@mui/material' {
	interface CustomTheme {
		textColor: {
			dark: string;
			primary: string;
			secondary: string;
			light: string;
		};
		borderColor: {
			textField: string;
			button: string;
			divider: string;
			toolbar: string;
			searchField: string;
		};
		chip: {
			admin: string;
			warning: string;
			observer: string;
			active: string;
			invited: string;
		};
		breadcrumb: {
			backgroundColor: string;
		};
	}

	export interface Theme extends CustomTheme {}
	export interface ThemeOptions extends CustomTheme {}
}

export const theme = createTheme({
	palette: {
		primary: {
			main: '#69A6B4',
			dark: '#2F7281',
			light: '#97D4E1',
			contrastText: '#fff',
		},
		secondary: {
			main: '#F5F5F5',
			dark: '#606E77',
			light: '#1A5360',
		},
		info: {
			main: '#F9FEFF',
		},
		error: {
			main: '#A94943',
		},
		text: {
			primary: '#49545B',
			secondary: '#606E77',
		},
		action: {
			selected: '#F1F7FA',
		},
	},
	typography: {
		fontFamily: 'Inter',
	},
	textColor: {
		dark: '#0B0E1E',
		primary: '#425057',
		secondary: '#7D919B',
		light: '#1B1D1F',
	},
	borderColor: {
		textField: '#8497A1',
		button: '#606E77',
		divider: '#8497A2',
		toolbar: '#F1F7FA',
		searchField: '#CDDEE7',
	},
	chip: {
		admin: '#69A6B4',
		warning: '#f6a623',
		active: '#69A6B4',
		observer: '#A798FF',
		invited: '#49545B',
	},
	breadcrumb: {
		backgroundColor: '#FFECDB',
	},
});
