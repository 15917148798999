import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import logo from '@/assets/icons/Logo.svg';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Theme,
} from '@mui/material';
import { Button } from './Button';
import useMixpanel from '@/hooks/useMixpanel';
import { PREVENT_ENTER_BUTTON_INPUTS } from '@/utils/constants';

type BootstrapDialogTitlePropsType = {
	onClose?: () => void;
	children: React.ReactNode;
	id: string;
	loading?: boolean;
};

type ModalPropsType = {
	open: boolean;
	handleClose?: () => void;
	title?: string | React.ReactNode;
	children: React.ReactNode;
	actionBtnTitle: string;
	handleSubmit?: () => void;
	validateData?: () => boolean;
	saveButtonStyle?: any;
	loading?: boolean;
	fullScreen?: boolean;
	disabledBtn?: boolean;
	backdropColor?: string;
	isPublic?: boolean;
};

const styles = {
	cancelBtn: {
		color: (theme: Theme) => theme.palette.text.secondary,
	},
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(3),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(2),
	},
}));

function BootstrapDialogTitle(props: BootstrapDialogTitlePropsType) {
	const { children, onClose, loading, ...other } = props;

	return (
		<DialogTitle
			color={'#49545B'}
			fontSize={'24px'}
			lineHeight="28.8px"
			fontFamily="Poppins"
			fontWeight="600"
			textTransform={'capitalize'}
			sx={{ m: 0, p: { xs: 1, sm: 2 }, fontSize: { xs: '18px', sm: '24px' } }}
			{...other}
		>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: { xs: 2, sm: 16 },
						color: (theme) => theme.palette.grey[500],
					}}
					disabled={loading}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export function Modal(props: ModalPropsType) {
	const {
		open,
		handleClose,
		title,
		children,
		actionBtnTitle,
		handleSubmit,
		validateData,
		saveButtonStyle,
		loading,
		fullScreen = false,
		disabledBtn,
		backdropColor,
		isPublic = false,
	} = props;
	const { track } = useMixpanel();

	const onClose = () => {
		track('Modal Closed', { modal_title: title });
		handleClose && handleClose();
	};

	const onSubmit = useCallback(() => {
		track('Modal Submitted', { modal_title: title });
		handleSubmit && handleSubmit();
	}, [handleSubmit, title, track]);

	const handleKeyPress = useCallback(
		(event: KeyboardEvent) => {
			const target = event.target as HTMLButtonElement;
			if (
				event.key === 'Enter' &&
				!PREVENT_ENTER_BUTTON_INPUTS.includes(target.name) &&
				open &&
				!disabledBtn
			) {
				onSubmit();
			}
		},
		[open, disabledBtn, onSubmit],
	);

	useEffect(() => {
		if (open) track('Modal Opened', { modal_title: title });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	useEffect(() => {
		if (open) {
			document.addEventListener('keydown', handleKeyPress);
		}
		return () => {
			document.removeEventListener('keydown', handleKeyPress);
		};
	}, [open, handleKeyPress]);

	return (
		<BootstrapDialog
			fullWidth
			fullScreen={fullScreen}
			className="backdrop"
			maxWidth="sm"
			onClose={!loading ? onClose : () => {}}
			aria-labelledby="customized-dialog-title"
			open={open}
			sx={{
				'& .MuiDialogContent-root': {
					padding: { xs: '16px', sm: '24px' },
				},
				...(backdropColor && {
					'& .MuiBackdrop-root': {
						backgroundColor: backdropColor,
					},
				}),
			}}
		>
			{title && (
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={onClose}
					loading={loading}
				>
					{isPublic ? (
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<Image
								src={logo}
								alt="logo"
								height={35}
								style={{ position: 'absolute', left: 16 }}
							/>
							{title}
						</Box>
					) : (
						title
					)}
				</BootstrapDialogTitle>
			)}
			<DialogContent dividers={true}>{children}</DialogContent>
			<DialogActions>
				{handleClose ? (
					<Button
						variant="text"
						text={'Cancel'}
						disabled={loading}
						onClick={onClose}
						customStyles={styles.cancelBtn}
					/>
				) : null}
				<Button
					loading={loading}
					text={actionBtnTitle}
					onClick={onSubmit}
					customStyles={{ height: '38px', ...saveButtonStyle }}
					disabled={(validateData && validateData()) || loading || disabledBtn}
				/>
			</DialogActions>
		</BootstrapDialog>
	);
}
