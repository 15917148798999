import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Box, Grid } from '@mui/material';
import { TextEditor } from '@/components';
import { DEFAULT_FORMAT_OPTIONS_WITH_MENTION } from '@/utils/constants';
import commonStyles from '@/utils/styles';
import { MentionItemType, UserType } from '@/utils/types';

const styles = {
	containerStyles: {
		...commonStyles.article,
		maxWidth: '870px',
		'& .ql-editor': {
			minHeight: 'calc(100vh - 300px)',
		},
		'& .ql-toolbar.ql-snow': {
			border: '1px solid #ccc !important;',
			borderBottom: 'none !important;',
		},
		'& .ql-container.ql-snow': {
			borderColor: '#ccc !important;',
			borderTop: 'none !important;',
		},
		'&:not(:has(.ql-toolbar)) .ql-container.ql-snow': {
			borderColor: '#ccc !important;',
		},
		'& ol': {
			padding: 0,
		},
		'& ul': {
			padding: 0,
		},
		'& .ql-mention-list-container li': {
			lineHeight: '44px',
		},
	},
	emptyEditorContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '870px',
		height: 'calc(100vh - 260px)',
		borderRadius: '8px',
		border: '1px solid #ccc',
	},
};

type EditorProps = {
	codox?: Record<string, any>;
	docId?: string;
	handleUpdateText?: (_key: string, _value: string) => void;
	readOnly?: boolean;
	text?: string;
	title?: string;
	type?: 'summary' | 'notes' | 'entityNotes';
	user?: UserType | null;
	usersForMentions?: MentionItemType[];
};

export const Editor = ({
	codox,
	docId,
	handleUpdateText,
	readOnly = false,
	text,
	type = 'summary',
	user,
	usersForMentions,
}: EditorProps) => {
	const [updatingMentionableUsers, setUpdatingMentionableUsers] = useState(false);

	const saveHandler = useRef(
		debounce((text) => {
			handleUpdateText!(type, text);
		}, 1000),
	);

	useEffect(() => {
		if (!usersForMentions) return;
		setUpdatingMentionableUsers(true);

		const timeout = setTimeout(() => {
			setUpdatingMentionableUsers(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [usersForMentions]);

	const onChange = (editorText: string) => {
		saveHandler.current(editorText);
	};

	return (
		<Box sx={{ height: '100%' }}>
			<Grid container direction={'column'} height={'100%'} gap={3} wrap="nowrap">
				<Grid item flexGrow={1}>
					{updatingMentionableUsers ? (
						<Box sx={styles.emptyEditorContainer} />
					) : (
						<TextEditor
							atValues={usersForMentions}
							dynamicToolbar={true}
							codox={codox}
							docId={`${docId}-${type}`}
							containerStyles={styles.containerStyles}
							formatOptions={DEFAULT_FORMAT_OPTIONS_WITH_MENTION}
							isCollaborative={true}
							onChange={onChange}
							value={text}
							user={user}
							readOnly={readOnly}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
